import React from 'react';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { FhirClientContext } from '../../FhirClientContext';
import './../../css/views/ImageCategory.scss';
import Badge from '@mui/material/Badge';
import { getExternalServerUrl, getExternalServiceRequestId } from '../utils/checkExternalReference';

import Anamnesis from './../../assets/images/TabAnamnesisIcon.svg';
import BloodGasAnalysis from './../../assets/images/TabBloodGasAnalysisIcon.svg';
import Haemodynamics from './../../assets/images/TabHaemodynamicsIcon.svg';
import InfectiousDisease from './../../assets/images/TabInfectiousDiseaseIcon.svg';
import Lab from './../../assets/images/TabLabIcon.svg';
import MedicalLetter from './../../assets/images/TabMedicalLetterIcon.svg';
import Others from './../../assets/images/TabOthersIcon.svg';
import Perfusors from './../../assets/images/TabPerfusorsIcon.svg';
import Radiology from './../../assets/images/TabRadiologyIcon.svg';
import Respiration from './../../assets/images/TabRespirationIcon.svg';

import Lightbox from '../elements/Lightbox/Lightbox';

class ImageCategory extends React.Component {
  static contextType = FhirClientContext;
  constructor(props) {
    super(props);
    this.state = {
      toggler: false,
      selectedCategory: '',
      categoryChanged: false,
    };

    this.toggleLightbox = this.toggleLightbox.bind(this);
  }

  setAttachmentTitle = (title, dataType) => {
    const { t } = this.props;

    if (title !== undefined) {
      return title;
    } else if (dataType != undefined) {
      if (dataType == 'application/pdf') {
        return 'PDF';
      } else if (dataType == 'image/jpeg' || 'image/png') {
        return t('Image');
      } else {
        return undefined;
      }
    }
  };

  getImageCategoryIcon(category) {
    switch (category) {
      case 'Anamnesis':
        return Anamnesis;
      case 'MedicalLetter':
        return MedicalLetter;
      case 'Haemodynamics':
        return Haemodynamics;
      case 'Respiration':
        return Respiration;
      case 'BloodGasAnalysis':
        return BloodGasAnalysis;
      case 'Perfusors':
        return Perfusors;
      case 'InfectiousDisease':
        return InfectiousDisease;
      case 'Radeology': // the category Radiology is misspelled in the app, so for compatibility reasons it is translated here
      case 'Radiology':
        return Radiology;
      case 'Lab':
        return Lab;
      case 'Others':
        return Others;
    }
  }

  componentDidMount() {}

  componentWillUnmount() {}

  toggleLightbox(e) {
    this.props.onSelectCategory(e.currentTarget.getAttribute('category'));
  }

  render() {
    const { t, i18n } = this.props;
    const sidebar = Object.keys(this.props.imageCategoryList).map((key, index) => {
      const category = this.props.imageCategoryList[key];
      return (
        <div key={index++}>
          <Button key={key} category={key} onClick={this.toggleLightbox} disabled={category.length == 0}>
            <div className="amp" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Badge badgeContent={category.length} color="primary" className="amountBadge">
                <img src={this.getImageCategoryIcon(key)} style={{ width: '50px', height: '50px' }} />
              </Badge>
              <span style={{ fontSize: 10 }}>{t(key)}</span>
            </div>
          </Button>
        </div>
      );
    });

    return (
      <div
        className="rightMenu"
        style={{
          height: '100%',
          right: '0px',
          justifyContent: 'space-evenly',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {sidebar}
      </div>
    );
  }
}

export default withTranslation()(ImageCategory);
