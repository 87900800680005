import React from 'react';
import { FhirClientContext } from '../../FhirClientContext';
import { withTranslation } from 'react-i18next';
import PdfThumbnail from './PdfThumbnail/PdfThumbnail';
import Thumbnail from './Thumbnail/Thumbnail';

class DocumentReference extends React.Component {
  static contextType = FhirClientContext;

  constructor(props) {
    super(props);
    this.state = {
      resourceData: null,
      fullResLoaded: false,
    };
  }

  async loadData() {
    const client = this.context.client;
    // load the title and contentType of the DocumentReference
    await client
      .request(this.props.id + '?_elements=content.attachment.title,content.attachment.contentType,category', {
        pageLimit: 0,
        flat: true,
      })
      .then(async docRefData => {
        // load the actual content data (thumbnail) with binary read
        await client
          .request(
            {
              url: `${this.props.id}/$binary-access-read?path=DocumentReference.content.attachment`,
              federatedServerUrl: this.props.externalServerUrl,
            },
            {
              pageLimit: 0,
              flat: true,
            }
          )
          // convert content to base64
          .then(data => data.blob())
          .then(async blob => {
            let imageB64 = await this.blobToBase64(blob);
            // look for related DocumentReferences (full resolution version of images)
            await client
              .request({
                url: `DocumentReference?relatesto=${this.props.id}`,
                federatedServerUrl: this.props.externalServerUrl,
              })
              .then(linkedFullResDocRef => {
                let linkedDocRefId = '';
                if (linkedFullResDocRef.entry) {
                  linkedDocRefId =
                    linkedFullResDocRef.entry[0].resource.resourceType + '/' + linkedFullResDocRef.entry[0].resource.id;
                }
                let isVideo = imageB64.includes('video');

                // the category Radiology is misspelled in the app, so for compatibility reasons it is translated here
                if (docRefData.category[0].text === 'Radeology') docRefData.category[0].text = 'Radiology';

                const resource = {
                  linkedDocRefId: linkedDocRefId,
                  data: imageB64,
                  id: this.props.id,
                  fullRes: null,
                  isVideo: isVideo,
                  comId: this.props.comId,
                  title: this.setAttachmentTitle(
                    docRefData.content[0].attachment.title,
                    docRefData.content[0].attachment.contentType
                  ),
                  dataType: docRefData.content[0].attachment.contentType,
                  category: docRefData.category[0].text,
                };

                // save content in state
                this.setState({
                  resourceData: resource,
                });

                // bubble docRef content up
                this.props.addDocumentReferenceData(resource);

                // bubble category entry up
                this.props.addCategoryEntry(this.props.id, docRefData.category[0].text);
              });
          });
      });
  }

  // set title according to data type
  setAttachmentTitle = (title, dataType) => {
    const { t } = this.props;

    if (title !== undefined) {
      return title;
    } else if (dataType != undefined) {
      if (dataType == 'application/pdf') {
        return 'PDF';
      } else if (dataType == 'image/jpeg' || 'image/png') {
        return t('Image');
      } else {
        return undefined;
      }
    }
  };

  blobToBase64 = blob =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  // load the full resolution version of the image from the server
  loadFullResPicture = async () => {
    const client = this.context.client;
    let image = this.state.resourceData;
    const imageId = image.linkedDocRefId;

    if (imageId !== '') {
      await client
        .request({
          url: `${imageId}/$binary-access-read?path=DocumentReference.content.attachment`,
          federatedServerUrl: this.props.externalServerUrl,
        })
        .then(data => {
          if (data) {
            return data.blob();
          } else return;
        })
        .then(async blob => {
          let fullResB64 = await this.blobToBase64(blob);
          image.fullRes = fullResB64;
          this.props.addFullResPicture(image);
          this.setState({ fullResLoaded: true });
        })
        .catch(err => console.log(err));
    }
  };

  // open pdf document in a new browser tab
  openPDF = base64data => {
    const base64String = base64data.substring(base64data.indexOf(',') + 1);
    const blob = this.base64ToBlob(base64String, 'application/pdf');
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  };

  componentWillUnmount() {}

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    // loads the full res version when the rest of the ressource is loaded
    if (this.state.resourceData && !this.state.fullResLoaded) {
      this.loadFullResPicture(this.state.resourceData.id);
    }
  }

  selectImage = () => {
    this.props.onSelectImage(this.state.resourceData.id);
  };

  render() {
    return (
      <div
        className="mediaElement"
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '8px', gap: '8px' }}
      >
        <Thumbnail
          isLoading={!this.state.resourceData}
          content={
            this.state.resourceData?.dataType == 'application/pdf' ? (
              <PdfThumbnail src={this.state.resourceData?.data} />
            ) : (
              <img src={this.state.resourceData?.data} width="100px" height="100px" style={{ objectFit: 'contain' }} />
            )
          }
          title={this.state.resourceData?.title}
          onClick={() => {
            this.state.resourceData?.data.includes('application/pdf')
              ? this.openPDF(this.state.resourceData?.data)
              : this.selectImage();
          }}
          type={this.state.resourceData?.dataType == 'application/pdf' ? 'pdf' : 'img'}
        />
      </div>
    );
  }
}

export default withTranslation()(DocumentReference);
