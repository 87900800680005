import { Router, Route } from 'react-router-dom';
import history from '../history';
import Launcher from './views/Launcher';
import Home from './views/Home';
import Menu from './views/Menu';
import CaseList from './views/CaseList';
import CaseDetail from './views/CaseDetail';
import NewCase from './views/NewCase';
import '../css/app.scss';
import Legal from './elements/Legal';
import Privacy from './elements/Privacy';
import {pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * Wraps everything into `FhirClientProvider` so that any component
 * can have access to the fhir client through the context.
 */

export default function App() {
  return (
    <div className="wrapperBox">
      <Router history={history}>
        <Menu />
        <Route path="/" component={Launcher} exact />
        <Route path="/app" component={Home} />
        <Route path="/new" component={NewCase} />
        <Route path="/cases/:show" component={CaseList} />
        <Route path="/casedetail/:id/(viewer)?/:dicomid?" component={CaseDetail} />
        <Route path="/legal" component={Legal} />
        <Route path="/privacy" component={Privacy} />
      </Router>
    </div>
  );
}
