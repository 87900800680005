import React from 'react';
import '../../css/views/CaseDetail.scss';
import colors from '../../css/theme.module.scss';
import { FhirClientContext } from '../../FhirClientContext';
import { withTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';
import { getOwnOrganization } from '../utils/getOwn';
import {
  getExternalServerUrl,
  getExternalServiceRequestId,
  isExternalReference,
} from '../utils/checkExternalReference';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

class CaseHeader extends React.Component {
  static contextType = FhirClientContext;

  constructor(props) {
    super(props);
    this.state = {
      patient: {
        id: 0,
        name: '',
        height: '',
        birthdate: '',
        gender: '',
        weight: '',
      },
      case: {
        payer: '',
        partner: [],
        counterpart: '',
        callbacknumber: '',
      },
      isArchived: false,
      isCloseRequested: false,
      isCaseConference: false,
      ownOrganization: '',
    };
  }

  async loadData() {
    const client = this.context.client;
    const { t } = this.props;
    await client
      .request(`ServiceRequest/${this.props.sr_id}`, {
        pageLimit: 0,
        flat: true,
      })
      .then(async sr => {
        await client
          .request(
            {
              url: `ServiceRequest/${getExternalServiceRequestId(sr, client) ?? this.props.sr_id}`,
              federatedServerUrl: getExternalServerUrl(sr, client),
            },
            {
              pageLimit: 0,
              flat: true,
              resolveReferences: ['performer', 'requester', 'contact'],
            }
          )
          .then(async data => {
            await client
              .request({ url: data.subject.reference, federatedServerUrl: getExternalServerUrl(sr, client) })
              .then(patientData => {
                const name = patientData.name;
                const patientName = this.buildPatientName(name);

                const externalId = getExternalServiceRequestId(sr, client);
                const externalUrl = getExternalServerUrl(sr, client);
                if (externalId) {
                  client
                    .request({ url: 'ServiceRequest/' + externalId, federatedServerUrl: externalUrl })
                    .then(srData => {
                      this.setState({ isCaseConference: srData.performer.length > 1 });
                    });
                } else {
                  this.setState({ isCaseConference: data.performer.length > 1 });
                }

                // case is archived
                if (client.getPath(data, 'status') == 'completed') {
                  this.setState({ isArchived: true });
                  this.props.setSrIsArchived(true);
                }

                // case should be closed
                if (client.getPath(data, 'status') == 'on-hold') {
                  this.setState({ isCloseRequested: true });
                  let holdBy = client.getPath(data, 'supportingInfo.0.reference');
                  if (holdBy.startsWith('PractitionerRole')) {
                    client
                      .request(`${holdBy}`, {
                        pageLimit: 0,
                        flat: true,
                      })
                      .then(practitionerRole => {
                        this.props.setSrIsOnHoldBy(practitionerRole.organization.reference);
                      });
                  } else {
                    this.props.setSrIsOnHoldBy(holdBy);
                  }
                }

                // am I requester or performer?
                let partner = '';

                // get requester organization
                let requesterOrg;
                if (client.getPath(data, 'requester.resourceType') == 'Organization') {
                  requesterOrg = 'Organization/' + client.getPath(data, 'requester.id');
                } else {
                  requesterOrg = client.getPath(data, 'requester.organization.reference');
                }

                let performers = data.performer.map(o => o.name);
                if (requesterOrg == this.state.ownOrganization) {
                  // I am requester
                  // TODO what if there are multiple performers?
                  this.setState({
                    case: {
                      ...this.state.case,
                      partner: performers,
                    },
                  });
                  this.props.setSrRecipient(client.getPath(data, 'performer.0.id'));
                } else {
                  // I am performer
                  partner = client.getPath(data, 'requester.name');
                  client
                    .request(
                      { url: `${requesterOrg}`, federatedServerUrl: externalUrl },
                      {
                        pageLimit: 0,
                        flat: true,
                      }
                    )
                    .then(partner => {
                      this.setState({
                        case: {
                          ...this.state.case,
                          partner: partner.name,
                        },
                      });
                      this.props.setSrRecipient(partner.id);
                    });
                }

                client
                  .request(
                    {
                      url: `Observation?subject=Patient/${patientData.id}`,
                      federatedServerUrl: externalUrl,
                    },
                    {
                      pageLimit: 0,
                      flat: true,
                    }
                  )
                  .then(data => {
                    data.forEach(o => {
                      // get weight
                      if (client.getPath(o, 'code.coding.0.code') == '29463-7') {
                        this.setState({
                          patient: {
                            ...this.state.patient,
                            weight:
                              client.getPath(o, 'valueQuantity.value') + ' ' + client.getPath(o, 'valueQuantity.unit'),
                          },
                        });
                      }

                      // get height
                      if (client.getPath(o, 'code.coding.0.code') == '8302-2') {
                        this.setState({
                          patient: {
                            ...this.state.patient,
                            height:
                              client.getPath(o, 'valueQuantity.value') + ' ' + client.getPath(o, 'valueQuantity.unit'),
                          },
                        });
                      }
                    });
                  });

                client
                  .request(
                    {
                      url: `Coverage?policy-holder=Patient/${patientData.id}`,
                      federatedServerUrl: externalUrl,
                    },
                    {
                      pageLimit: 0,
                      flat: true,
                    }
                  )
                  .then(data => {
                    this.setState({
                      case: {
                        ...this.state.case,
                        payer: client.getPath(data, '0.class.0.name'),
                      },
                    });
                  });

                this.setState({
                  patient: {
                    ...this.state.patient,
                    id: patientData.id,
                    name: patientName,
                    gender: t(patientData.gender),
                    birthdate: format(parseISO(patientData.birthDate), 'P', { locale: de }),
                  },
                  case: {
                    ...this.state.case,
                    counterpart: patientData.contact[0].name.family,
                    callbacknumber: patientData.contact[0].telecom ? patientData.contact[0].telecom[0].value : '',
                  },
                });
                this.props.setSrRequester(client.getPath(data, 'requester.id'));
                this.props.setSrSubject(patientData.id);
              });
          });
      });
  }

  buildPatientName(name) {
    const { t } = this.props;
    if (!name) return t('No Name');

    const entry = name.find(nameRecord => nameRecord.use === 'official') || name[0];
    if (!entry) return t('No Name');

    return entry.given.join(' ') + ' ' + entry.family;
  }

  componentWillUnmount() {}

  async componentDidMount() {
    // check for own organization
    const client = this.context.client;
    this.setState({ ownOrganization: await getOwnOrganization(client) });

    await this.loadData();
  }

  render() {
    const { t } = this.props;
    const archivedHeader =
      this.state.isArchived || this.props.srIsArchived ? (
        <div className="amp archivedHeader">{t("This consultation is archived and can't be edited.")}</div>
      ) : null;
    const closeRequestedHeader =
      this.state.isCloseRequested || this.props.srIsOnHoldBy ? (
        <div className="amp archivedHeader">
          {t('This consultation is readonly because there is an open request for closing this consultation.')}
        </div>
      ) : null;

    const leftHeaderBlock = (
      <div className="blockLeft">
        <div className="amp casePartner">
          <FontAwesomeIcon
            style={{
              display: this.state.isCaseConference ? 'inline' : 'none',
              color: colors.ampDarkestGrey,
            }}
            icon={faUsers}
          />
          <span
            title={
              typeof this.state.case.partner == 'string' ? this.state.case.partner : this.state.case.partner.join(', ')
            }
            style={{ marginLeft: '.2em' }}
          >
            {typeof this.state.case.partner == 'string'
              ? this.state.case.partner
              : this.state.case.partner.length > 1
              ? t('Case conference with participants', { count: this.state.case.partner.length })
              : this.state.case.partner[0]}{' '}
          </span>
        </div>
        <div className="counterpart">{t('Counterpart')}</div>
        <div className="horizontalSeparator"></div>
        <div className="insurance">{t('Insurance')}</div>
        <div className="amp casePayer">{this.state.case.payer}</div>
      </div>
    );

    const middleHeaderBlock = (
      <div className="parent">
        <div className="amp patientNameInfo" style={{ fontSize: '28px' }}>
          {this.state.patient.name}
        </div>
        <div className="amp upperLeftInfo tbody" style={{ justifySelf: 'flex-end' }}>
          {this.state.patient.birthdate}
        </div>
        <div className="upperHorizontalDivider">
          <div className="horizontalSeparator"></div>
        </div>
        <div className="lowerHorizontalDivider">
          <div className="horizontalSeparator"></div>
        </div>
        <div className="amp upperRightInfo tbody" style={{ justifySelf: 'flex-start' }}>
          {this.state.patient.gender}
        </div>
        <div className="amp lowerLeftInfo tbody" style={{ justifySelf: 'flex-end' }}>
          {this.state.patient.height}
        </div>
        <div className="amp lowerRightInfo tbody" style={{ justifySelf: 'flex-start' }}>
          {this.state.patient.weight}
        </div>
        <div className="verticalDivider">
          <div className="verticalSeparator"></div>
        </div>
      </div>
    );

    const rightHeaderBlock = (
      <div className="blockRight">
        <div className="amp caseCounterpart">{this.state.case.counterpart}</div>
        <div className="contactPerson">{t('Contact person')}</div>
        <div className="horizontalSeparator"></div>
        <div className="cNumber">{t('Callback Number')}</div>
        <div className="amp callbackNumber">{this.state.case.callbacknumber}</div>
      </div>
    );

    return (
      <>
        <div className="amp caseHeader">
          {leftHeaderBlock}
          {middleHeaderBlock}
          {rightHeaderBlock}
        </div>
        {archivedHeader}
        {closeRequestedHeader}
      </>
    );
  }
}

export default withTranslation()(CaseHeader);
