import React from 'react';
import '../../css/views/CaseDetail.scss';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CommunicationList from '../elements/CommunicationList';
import CaseHeader from '../elements/CaseHeader';
import CaseFooter from '../elements/CaseFooter';
import DicomViewer from '../elements/DicomViewer';
import FhirClientProvider from '../structures/FhirClientProvider';
import Videoconference from '../elements/Videoconference';
import SplitPane from 'react-split-pane';
import ImageCategory from './ImageCategory';
import Lightbox from '../elements/Lightbox/Lightbox';

/**
 * Wraps everything into `FhirClientProvider` so that any component
 * can have access to the fhir client through the context.
 */
class CaseDetail extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      srRequester: '',
      srSubject: '',
      srCategory: '',
      srRecipient: '',
      isArchived: false,
      isOnHoldBy: '',
      showDicom: false,
      ownDisplayname: t('Unknown user'),
      videoconf: '',
      videoconfActive: false,
      showVideoconf: false,
      communications: [],
      documentReferences: [],
      imageCategoryList: {
        Anamnesis: [],
        MedicalLetter: [],
        Haemodynamics: [],
        Respiration: [],
        BloodGasAnalysis: [],
        Perfusors: [],
        InfectiousDisease: [],
        Radiology: [],
        Lab: [],
        Others: [],
      },
      lightboxToggler: false,
      lightboxSelectedId: null,
      lightboxSlide: 0,
      lightboxSelectedCategory: null,
    };
    this.setSrRequester = this.setSrRequester.bind(this);
    this.setSrSubject = this.setSrSubject.bind(this);
    this.setSrIsArchived = this.setSrIsArchived.bind(this);
    this.setSrIsOnHoldBy = this.setSrIsOnHoldBy.bind(this);
    this.setVideoconferenceLink = this.setVideoconferenceLink.bind(this);
    this.setVideoconferenceActive = this.setVideoconferenceActive.bind(this);
    this.setOwnDisplayname = this.setOwnDisplayname.bind(this);
    this.addCommunication = this.addCommunication.bind(this);
    this.reRender = this.reRender.bind(this);
    this.updateCommunications = this.updateCommunications.bind(this);
    this.toggleDicomView = this.toggleDicomView.bind(this);
  }

  componentWillUnmount() {}

  componentDidMount() {}

  setSrRequester = requester => {
    this.setState({ srRequester: requester });
  };

  setSrSubject = subject => {
    this.setState({ srSubject: subject });
  };

  setSrCategory = category => {
    this.setState({ srCategory: category });
  };

  setSrRecipient = recipient => {
    this.setState({ srRecipient: recipient });
  };

  reRender = () => {
    this.forceUpdate();
  };

  setSrIsArchived = archived => {
    this.setState({ isArchived: archived });
  };

  setSrIsOnHoldBy = requester => {
    this.setState({ isOnHoldBy: requester });
  };

  setVideoconferenceLink = link => {
    this.setState({ videoconf: link, videoconfActive: true });
  };

  setVideoconferenceActive = state => {
    this.setState({ videoconfActive: state });
  };

  showVideoconf = show => {
    this.setState({ showVideoconf: show });
  };

  onJoinVideoconferenceButtonClick = () => {
    this.setState({ showVideoconf: true });
  };

  setOwnDisplayname = name => {
    this.setState({ ownDisplayname: name });
  };

  setVideoconferenceLink = link => {
    this.setState({ videoconf: link, videoconfActive: true });
  };

  setVideoconferenceActive = state => {
    this.setState({ videoconfActive: state });
  };

  showVideoconf = show => {
    this.setState({ showVideoconf: show });
  };

  onJoinVideoconferenceButtonClick = () => {
    this.setState({ showVideoconf: true });
  };

  setOwnDisplayname = name => {
    this.setState({ ownDisplayname: name });
  };

  addCommunication = (id, addToBeginning) => {
    if (this.state.communications.includes(id)) {
      return;
    }
    // we get sorted input from the server, so have to add them in the right order
    addToBeginning
      ? this.setState({ communications: [id, ...this.state.communications] })
      : this.setState({ communications: [...this.state.communications, id] });
  };

  // save loaded communications in state
  addCommunicationList = communications => {
    this.setState({ communications: communications });
  };

  // add entry to image category
  addCategoryEntry = (imageId, category) => {
    let categoryList = this.state.imageCategoryList;
    // the category Radiology is misspelled in the app, so for compatibility reasons it is translated here
    if (category === 'Radeology') category = 'Radiology';
    // category does not exist, default to "others"
    if (!(category in categoryList)) category = 'Others';
    // image is already in category
    if (categoryList[category].includes(imageId)) return;

    categoryList[category].push(imageId);
    this.setState({ imageCategoryList: categoryList });
  };

  // add docRef data to the storage
  addDocumentReferenceData = data => {
    let docRefs = this.state.documentReferences;
    // dont add the DocRef if its already in the list
    if (!docRefs.some(docRef => docRef.id === data.id)) {
      data.type = this.getDataType(data);
      docRefs.push(data);
      // sort the array to match view in communication and lightbox
      docRefs.sort((a, b) => a.id.split('/')[1] - b.id.split('/')[1]);
      this.setState({ docRefs });
    }
  };

  addFullResPicture = data => {
    // replace the stored DocumentReference with the new one containing the full res image
    const index = this.state.documentReferences.map(e => e.id).indexOf(data.id);
    const docRefs = this.state.documentReferences;
    data.type = this.getDataType(data);
    if (index !== -1) {
      docRefs[index] = data;
    } else {
      docRefs.push(data);
    }
    this.setState({ documentReferences: docRefs });
  };

  getDataType = resource => {
    if (resource.isVideo) {
      return 'video';
    } else if (resource.data.includes('application/pdf')) {
      return 'pdf';
    } else if (resource.data.includes('image/')) {
      return 'img';
    } else {
      return null;
    }
  };

  updateCommunications = delMe => {
    // this is painful but it's the only way right now
    let comms = this.state.communications;
    if (comms.includes(delMe)) {
      let index = comms.indexOf(delMe);
      comms.splice(index, 1);
      this.setState({ communications: comms });
    }
    this.setState({ communications: [delMe, ...this.state.communications] });
  };

  toggleDicomView = () => {
    this.setState({ showDicom: !this.state.showDicom });
  };

  onSelectCategory = category => {
    this.setState({
      lightboxToggler: !this.state.lightboxToggler,
      lightboxSelectedId: null,
      lightboxSelectedCategory: category,
      lightboxSlide: 0,
    });
  };

  onSelectImage = (comId, imageId, index) => {
    this.setState({
      lightboxToggler: !this.state.lightboxToggler,
      lightboxSelectedId: { imageId: imageId, comId: comId },
      lightboxSlide: index,
      lightboxSelectedCategory: null,
    });
  };

  render() {
    const { t } = this.props;

    let lightboxContent;
    let lightboxTitle = t('Message');

    if (this.state.lightboxSelectedCategory) {
      // select all DocumentReferences that match the selected category
      lightboxContent = this.state.documentReferences.reduce((selectedDocRefs, currentDocRef) => {
        if (currentDocRef.category == this.state.lightboxSelectedCategory) selectedDocRefs.push(currentDocRef);
        return selectedDocRefs;
      }, []);
      lightboxTitle = t(this.state.lightboxSelectedCategory);
    }

    if (this.state.lightboxSelectedId) {
      // select all DocumentReferences that are in the same Communication as the selected DocRef
      lightboxContent = this.state.documentReferences.reduce((selectedDocRefs, currentDocRef) => {
        if (currentDocRef.comId == this.state.lightboxSelectedId.comId) selectedDocRefs.push(currentDocRef);
        return selectedDocRefs;
      }, []);
      // sort the array to match view in communication and lightbox
      lightboxContent.sort((a, b) => a.id.split('/')[1] - b.id.split('/')[1]);
    }

    const lightbox = (
      <Lightbox
        galleryName={lightboxTitle}
        toggler={this.state.lightboxToggler}
        contents={lightboxContent}
        index={this.state.lightboxSlide}
      />
    );

    let content;
    if (this.state.showDicom || this.props.match.params.dicomid) {
      content = (
        <>
          <DicomViewer sr_id={this.props.match.params.id} study_id={this.props.match.params.dicomid} />
          <Link to={`..`}>
            <Button variant="contained" color="primary">
              {t('Back')}
            </Button>
          </Link>
        </>
      );
    } else {
      const innerContent = (
        <>
          <CommunicationList
            sr_id={this.props.match.params.id}
            srRequester={this.state.srRequester}
            setVideoconferenceLink={this.setVideoconferenceLink}
            setVideoconferenceActive={this.setVideoconferenceActive}
            onJoinVideoconferenceButtonClick={this.onJoinVideoconferenceButtonClick}
            showVideoconf={this.state.showVideoconf}
            reRender={this.reRender}
            addCommunicationList={this.addCommunicationList}
            communications={this.state.communications}
            addDocRef={this.addDocRef}
            isReadOnly={this.state.isOnHoldBy || this.state.isArchived}
            addCategoryEntry={this.addCategoryEntry}
            addDocumentReferenceData={this.addDocumentReferenceData}
            addFullResPicture={this.addFullResPicture}
            onSelectImage={this.onSelectImage}
          />
          <CaseFooter
            sr_id={this.props.match.params.id}
            srSubject={this.state.srSubject}
            srCategory={this.state.srCategory}
            srRecipient={this.state.srRecipient}
            reRender={this.reRender}
            srIsOnHoldBy={this.state.isOnHoldBy}
            srIsArchived={this.state.isArchived}
            videoconfActive={this.state.videoconfActive}
            setVideoconferenceLink={this.setVideoconferenceLink}
            setOwnDisplayname={this.setOwnDisplayname}
            addCommunication={this.addCommunication}
            setSrIsArchived={this.setSrIsArchived}
            setSrIsOnHoldBy={this.setSrIsOnHoldBy}
            updateCommunications={this.updateCommunications}
            toggleDicomView={this.toggleDicomView}
          />
        </>
      );

      if (this.state.showVideoconf) {
        content = (
          <SplitPane split="vertical" minSize={500} defaultSize={800} style={{ position: 'relative' }}>
            {innerContent}
            <Videoconference
              setVideoconferenceActive={this.setVideoconferenceActive}
              showVideoconf={this.showVideoconf}
              roomName={this.state.videoconf}
              userName={this.state.ownDisplayname}
            />
          </SplitPane>
        );
      } else {
        content = innerContent;
      }
    }

    return (
      <FhirClientProvider>
        <div className="caseDetailBox">
          <div className="caseDetail mainView">
            <CaseHeader
              sr_id={this.props.match.params.id}
              setSrRequester={this.setSrRequester}
              setSrSubject={this.setSrSubject}
              setSrCategory={this.setSrCategory}
              setSrRecipient={this.setSrRecipient}
              setSrIsArchived={this.setSrIsArchived}
              setSrIsOnHoldBy={this.setSrIsOnHoldBy}
              srIsOnHoldBy={this.state.isOnHoldBy}
              srIsArchived={this.state.isArchived}
            />
            {content}
            {lightbox}
          </div>
          <ImageCategory imageCategoryList={this.state.imageCategoryList} onSelectCategory={this.onSelectCategory} />
        </div>
      </FhirClientProvider>
    );
  }
}

export default withTranslation()(CaseDetail);
