/*
Copyright 2024 Awesome Technologies Innovationslabor GmbH

All rights reserved
*/

import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import './Lightbox.css';
import Icon from './Icons';
import LazyLoadingImages from '../../utils/LazyLoadingImages';
import PdfThumbnail from '../PdfThumbnail/PdfThumbnail';
import { withTranslation, useTranslation } from 'react-i18next';
import { TailSpin } from 'react-loader-spinner';

/**
 * @typedef Props
 * @prop {boolean} isLoading Shows the loading state while true
 * @prop {string} [title] The name underneath the thumbnail
 * @prop {any} content The component that should be displayed
 * @prop {Function} onClick What happens when the thumbnail is clicked
 * @prop {string} [type] What type the file has (gets displayed as an icon in the bottom right)
 * @prop {string} [tooltip] The text that gets shown when hovered over
 * @prop {boolean} [selected] If the Thumbnail is selected (borders turn yellow)
 */

/**
 * Component for showing thumbnails of content in the Lightbox. Use optional chaining (?.) in all props that need to be loaded.
 *
 * @param {Props} props
 */
const Thumbnail = ({
  isLoading = false,
  title = '',
  content = null,
  onClick = null,
  type = null,
  tooltip,
  selected,
}) => {
  const { t } = useTranslation();

  // This controls the little file icon in the bottom right corner of the thumbnail
  const getTypeIcon = type => {
    if (type == 'pdf') {
      return <Icon icon={'pdfFile'} />;
    } else if (type == 'img') {
      return <Icon icon={'imageFile'} />;
    } else {
      return null;
    }
  };

  // The skeleton with the loading spinner is shown when the thumbnail is loading
  return isLoading ? (
    <button className={'Lightbox__ThumbnailSkeleton'}>
      <div className="Lightbox__ThumbnailSkeleton__content">
        <TailSpin
          ariaLabel="loading-indicator"
          color="#21264e"
          height="32"
          width="32"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        />
      </div>
      <span className="Lightbox__ThumbnailSkeleton__title">{t('Loading..')}</span>
    </button>
  ) : (
    <button
      className={`Lightbox__Thumbnail ${selected ? 'Lightbox__Thumbnail--selected' : ''}`}
      title={tooltip}
      onClick={onClick}
    >
      <div className="Lightbox__Thumbnail__content">
        <LazyLoadingImages>{content}</LazyLoadingImages>
        {!!getTypeIcon(type) && <div className="Lightbox__Thumbnail__fileType">{getTypeIcon(type)}</div>}
      </div>
      <span className="Lightbox__Thumbnail__title">{title}</span>
    </button>
  );
};

/**
 * @typedef Content
 * @prop {any} id Index of the file
 * @prop {string} title Title of the file
 * @prop {string} type Can be "img", "video", "pdf" or null
 * @prop {any} data base64 data
 * @prop {boolean} [allowZoom] Allow zoom on this file
 */

/**
 * @typedef Props
 * @prop {number} [index] What index the lightbox should start on when opened
 * @prop {boolean} toggler When this value changes the lightbox opens or closes. Doesn't change when toggler isnt initialized.
 * @prop {string} [galleryName] The name of the gallery (Gets displayed above the thumbnails on the far left side)
 * @prop {Content[]} contents An array of files to be displayed
 * @prop {Function} [onSlideChange] Gets executed when the index was changed and returns the new index
 * @prop {boolean} [openOnMount] When this is initialized (not null, not undefined) the lightbox opens
 */

/**
 * Lightbox component
 *
 * @param {Props} props
 *
 * @example
 * <Lightbox
 *    toggler={toggler}
 *    galleryName={"Example Gallery"}
 *    contents={[
 *      {
 *        id: 1,
 *        title: "Picture 1",
 *        type: "img",
 *        data: "base64",
 *        allowZoom: true,
 *      },
 *      {
 *        id: 2,
 *        title: "PDF 1",
 *        type: "pdf",
 *        data: "base64",
 *        allowZoom: false,
 *      },
 *      {
 *        id: 2,
 *        title: "Video 1",
 *        type: "video",
 *        data: "base64",
 *        allowZoom: false,
 *      }
 *    ]}
 * />
 */
const Lightbox = ({ index = 0, toggler, galleryName, contents = [], onSlideChange }) => {
  const thumbnailContainerListRef = useRef(null);
  const contentContainerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(index);
  const [currentContents, setCurrentContents] = useState([]);
  const [zoom, setZoom] = useState(0.5);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [allowZoom, setAllowZoom] = useState(false);
  const [maxWidth, setMaxWidth] = useState(null);
  const [maxHeight, setMaxHeight] = useState(null);
  const { t } = useTranslation();
  const contentRef = useRef(null);

  // Toggles visibility if toggler is initialized and switches value
  useEffect(() => {
    if ((toggler === true || toggler === false) && contents.length > 0) {
      setIsVisible(prevVisible => !prevVisible);
    }
  }, [toggler]);

  // Resets to default zoom level on lightbox open and locks background html in place (removes broken scrollbars)
  useEffect(() => {
    if (isVisible) {
      handleResetZoom();
      document.body.classList.add('Lightbox__html');
    } else {
      document.body.classList.remove('Lightbox__html');
      setZoom(0.5); // Just for animation purposes
    }
  }, [isVisible]);

  // Fullscreen toggle
  const toggleFullscreen = () => {
    if (!isFullscreen) {
      // Enter fullscreen mode
      setFullscreen(true);
    } else {
      // Exit fullscreen mode
      setFullscreen(false);
    }
  };

  const setFullscreen = (mode = true) => {
    if (mode == isFullscreen) return; // Skips if no change otherwise an error appears
    if (mode) {
      // Enter fullscreen mode
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(mode);
  };

  // Handle onSlideChange
  useEffect(() => {
    if (onSlideChange) onSlideChange(currentIndex);
  }, [currentIndex]);

  // Handle content changes
  useEffect(() => {
    if (contents.length > 0) {
      setCurrentIndex(index);
      setCurrentContents(contents.map(o => ({ ...o, title: setAttachmentTitle(o?.title, o?.type) })));
    }
  }, [contents]);

  // Handle index changes
  useEffect(() => {
    if (contents.length > 0) {
      setCurrentIndex(index);
    }
  }, [index]);

  // Checks if zoom is allowed on currentIndex file
  useEffect(() => {
    if (currentContents.length > 0 && currentContents[currentIndex]?.allowZoom !== undefined) {
      setAllowZoom(currentContents[currentIndex].allowZoom);
    } else if (currentContents[currentIndex]?.type == 'img') {
      setAllowZoom(true);
    } else setAllowZoom(false);
  }, [currentContents, currentIndex]);

  const changeCurrentIndexBy = amount => {
    setCurrentIndex(prevCurrentIndex => {
      const newIndex = prevCurrentIndex + amount;
      return (newIndex + currentContents.length) % currentContents.length;
    });
  };

  // Zoom & pan selected picture
  useEffect(() => {
    if (!allowZoom) {
      handleResetZoom(); // If zoom isn't allowed anymore, reset zoom
    }
  }, [allowZoom]);

  const handleMouseDown = () => {
    if (!allowZoom) return;
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    if (!allowZoom) return;
    setIsDragging(false);
  };

  const handleZoomIn = () => {
    if (!allowZoom) return;
    setZoom(x => Math.min(x * 1.2, 10)); // zoom 1000% limit
  };

  const handleZoomOut = () => {
    if (!allowZoom) return;
    let newZoom = zoom / 1.2;
    if (zoom <= 1.1) {
      newZoom = 1;
      setPosition({ x: 0, y: 0 }); // Reset position if zooming out below a threshold
    }
    setZoom(newZoom);
  };

  const handleResetZoom = () => {
    setZoom(1);
    setPosition({ x: 0, y: 0 });
  };

  const handleMouseMove = e => {
    if (!allowZoom || e.buttons !== 1 || zoom <= 1) return;
    const newX = position.x + e.movementX / zoom;
    const newY = position.y + e.movementY / zoom;
    setPosition({ x: newX, y: newY });
  };

  // Image Zoom
  const handleScroll = e => {
    if (!allowZoom) return;
    if (e.deltaY > 0) {
      handleZoomOut();
    } else {
      handleZoomIn();
    }
  };

  // Let scrollwheel scroll thumbnail container horizontally
  const handleThumbnailContainerListScroll = e => {
    const container = thumbnailContainerListRef.current;
    container.scrollLeft += e.deltaY;
  };

  // Fit and resize selected picture
  const updateShortestSide = () => {
    if (contentContainerRef.current) {
      const width = contentContainerRef.current.offsetWidth;
      const height = contentContainerRef.current.offsetHeight;
      setMaxWidth(Math.floor(width * 0.8));
      setMaxHeight(Math.floor(height * 0.8));
    }
  };

  useLayoutEffect(() => {
    updateShortestSide();
    window.addEventListener('resize', updateShortestSide);
    return () => window.removeEventListener('resize', updateShortestSide);
  }, []);

  useLayoutEffect(() => {
    if (isVisible) updateShortestSide();
  }, [isVisible]);

  // Handle keyboard presses
  useEffect(() => {
    const handleKeyPress = event => {
      handleResetZoom();

      switch (event.key) {
        case 'ArrowLeft':
          changeCurrentIndexBy(-1);
          break;
        case 'ArrowRight':
          changeCurrentIndexBy(1);
          break;
        case 'Escape':
          setIsVisible(false);
          setFullscreen(false);
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  });

  // handle fullscreen changes
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  // Open base64 PDF in new tab
  const openPDFInNewTab = base64data => {
    if (currentContents[currentIndex]?.type == 'pdf') {
      const base64String = base64data.substring(base64data.indexOf(',') + 1);
      const blob = base64ToBlob(base64String, 'application/pdf');
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }
  };

  // Download base64Data file directly
  const downloadFile = (base64Data, fileName) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = base64Data;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  // Get correct html element for data type
  const getContent = content => {
    // show full res version if available
    const data = content.fullRes ? content.fullRes : content.data;
    if (content.type == 'pdf') {
      return <PdfThumbnail src={data} height={maxHeight} width={maxWidth} />;
    } else if (content.type == 'img') {
      return <img src={data} height={maxHeight} />;
    } else if (content.type == 'video') {
      return <video src={data} controls />;
    } else {
      return data;
    }
  };

  // Set title if none available
  const setAttachmentTitle = (title, dataType) => {
    if (title !== undefined) {
      return title;
    } else if (dataType != undefined) {
      if (dataType == 'pdf') {
        return 'PDF';
      } else if (dataType == 'img') {
        return t('Image');
      } else {
        return undefined;
      }
    }
  };

  return isVisible ? (
    <div className="Lightbox">
      <div className="Lightbox__toolbar">
        {allowZoom && (
          <div className="Lightbox__toolbarGroup">
            {zoom != 1 && (
              <>
                <button className="Lightbox__toolbar__button" onClick={handleResetZoom} title={t('Reset Zoom')}>
                  <Icon icon={'undo'} />
                  {t('Reset Zoom')}
                </button>
                <button className="Lightbox__toolbar__button" onClick={handleZoomOut} title={t('Zoom Out')}>
                  <Icon icon={'zoomOut'} />
                </button>
              </>
            )}
            <button className="Lightbox__toolbar__button" onClick={handleZoomIn} title={t('Zoom In')}>
              <Icon icon={'zoomIn'} />
            </button>
          </div>
        )}
        {(currentContents[currentIndex]?.type == 'pdf' || currentContents[currentIndex]?.type == 'img') && (
          <div className="Lightbox__toolbarGroup">
            {currentContents[currentIndex]?.type == 'pdf' && (
              <button
                className="Lightbox__toolbar__button"
                title={t('Open in new tab')}
                onClick={() => openPDFInNewTab(currentContents[currentIndex].data)}
              >
                <Icon icon={'openExternally'} />
              </button>
            )}
            <button
              className="Lightbox__toolbar__button"
              title={t('Download file')}
              onClick={() =>
                downloadFile(
                  currentContents[currentIndex].fullRes
                    ? currentContents[currentIndex].fullRes
                    : currentContents[currentIndex].data,
                  currentContents[currentIndex].title || 'download'
                )
              }
            >
              <Icon icon={'download'} />
            </button>
          </div>
        )}
        <div className="Lightbox__toolbarGroup">
          <button className="Lightbox__toolbar__button" onClick={toggleFullscreen} title={t('Fullscreen')}>
            <Icon icon={isFullscreen ? 'shrinkFullscreen' : 'fullscreen'} />
          </button>
          <button
            className="Lightbox__toolbar__button Lightbox__toolbar__button--close"
            title={t('Close')}
            onClick={() => {
              setFullscreen(false);
              setIsVisible(false);
            }}
          >
            <Icon icon={'close'} />
          </button>
        </div>
      </div>

      <div
        className="Lightbox__contentContainer"
        ref={contentContainerRef}
        onWheel={handleScroll}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div
          className="Lightbox__content"
          ref={contentRef}
          style={{
            transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
            transformOrigin: '50% 50%',
            transition: isDragging ? 'none' : 'transform 0.2s',
            maxHeight: `${maxHeight}px`,
            maxWidth: `${maxWidth}px`,
            zIndex: `${currentContents[currentIndex].type == 'img' ? '-1' : '0'}`,
          }}
          onClick={() => openPDFInNewTab(currentContents[currentIndex].data)}
        >
          <LazyLoadingImages>{getContent(currentContents[currentIndex])}</LazyLoadingImages>
        </div>

        <button
          className={`Lightbox__content__switch Lightbox__content__switch--left ${
            zoom != 1 ? 'Lightbox__content__switch--left--hide' : ''
          }`}
          onClick={() => changeCurrentIndexBy(-1)}
          title={t('Back')}
        >
          <Icon icon={'arrowLeft'} />
        </button>

        <button
          className={`Lightbox__content__switch Lightbox__content__switch--right ${
            zoom != 1 ? 'Lightbox__content__switch--right--hide' : ''
          }`}
          onClick={() => changeCurrentIndexBy(1)}
          title={t('Next')}
        >
          <Icon icon={'arrowRight'} />
        </button>
      </div>

      <div className={`Lightbox__thumbnailContainer ${zoom != 1 ? 'Lightbox__thumbnailContainer--hide' : ''}`}>
        <div className="Lightbox__thumbnailContainer__titleContainer">
          {!!galleryName && <span>{galleryName}</span>}
          <Icon icon={'chevronRight'} />
          <span className='Lightbox__thumbnailContainer__titleContainer__title'>{currentContents[currentIndex].title}</span>
          <span style={{ marginLeft: 'auto' }}>
            {currentIndex + 1}/{currentContents.length}
          </span>
        </div>
        <div
          className="Lightbox__thumbnailContainer__list"
          onWheel={handleThumbnailContainerListScroll}
          ref={thumbnailContainerListRef}
        >
          {currentContents.map((content, index) => (
            <Thumbnail
              isLoading={!content.data}
              key={content.id}
              title={content.title}
              tooltip={t('Select thumbnailName', { title: content.title })}
              content={
                content.type == 'pdf' ? (
                  <PdfThumbnail src={content.data} />
                ) : (
                  <img src={content.data} width="100px" height="100px" style={{ objectFit: 'contain' }} />
                )
              }
              selected={index == currentIndex}
              onClick={() => setCurrentIndex(index)}
              type={content.type}
            />
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default withTranslation()(Lightbox);
