/*
Copyright 2024 Awesome Technologies Innovationslabor GmbH

All rights reserved
*/

import React from 'react';

const recursionDepthLimit = 5;

/**
 * @typedef Props
 * @prop {any} children The components that should be optimized
 */

/**
 * Adds loading="lazy" to every image inside a component to optimize performance
 *
 * @param {Props} props
 *
 * @example
 * <LazyLoadingImages>
 *    <p>This is some content</p>
 *    <img src="image1.jpg" alt="Image 1" />
 *    <div>
 *      <p>Nested content</p>
 *      <img src="image2.jpg" alt="Image 2" />
 *    </div>
 * </LazyLoadingImages>
 */
const LazyLoadingImages = ({ children }) => {
  const recursivelyAddLazyLoading = (children, depth = 0) => {
    // Stops recursion going too deep
    if (depth >= recursionDepthLimit) {
      return children;
    }

    return React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        if (child.props.children) {
          // If the child has children, recursively apply lazy loading
          return React.cloneElement(child, {
            children: recursivelyAddLazyLoading(child.props.children, depth + 1),
          });
        } else if (child.type === 'img') {
          // Clone the img element with the added loading="lazy" attribute
          return React.cloneElement(child, { loading: 'lazy' });
        }
      }
      return child;
    });
  };

  const modifiedChildren = recursivelyAddLazyLoading(children);

  return modifiedChildren;
};

export default LazyLoadingImages;
