import React from 'react';
import { FhirClientContext } from '../../FhirClientContext';
import { withTranslation } from 'react-i18next';
import { Card, CardContent, Typography } from '@mui/material';
import '../../css/views/Stats.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClinicMedical } from '@fortawesome/free-solid-svg-icons';
import { TailSpin } from 'react-loader-spinner';

class Stats extends React.Component {
  static contextType = FhirClientContext;

  constructor(props) {
    super(props);
    this.state = {
      practitionerName: '',
      organizationName: '',
      dataLoaded: false,
    };
  }

  async componentDidMount() {
    // check for own organization
    const client = this.context.client;

    // organization is present in jwt claim
    if (client.getIdToken().fhirOrganization != undefined) {
      // read org details
      await client
        .request(`${client.getIdToken().fhirOrganization}`, {
          pageLimit: 0,
          flat: true,
        })
        .then(data => {
          this.setState({ practitionerName: '-', organizationName: data.name, dataLoaded: true });
        });
    }

    // practitioner is present in jwt claim
    if (client.getIdToken().fhirUser != undefined) {
      // read org for user
      await client
        .request(`PractitionerRole?practitioner=${client.getIdToken().fhirUser}`, {
          pageLimit: 0,
          flat: true,
          resolveReferences: ['organization', 'practitionerRole'],
        })
        .then(data => {
          this.setState({
            practitionerName: data[0].practitioner.display,
            organizationName: data[0].organization.name,
            dataLoaded: true,
          });
        });
    }
  }

  componentWillUnmount() {}

  render() {
    const { t } = this.props;

    const userInfo = this.state.dataLoaded ? (
      <Typography className="cardContents" variant="body2" component="div">
        <FontAwesomeIcon icon={faUser} style={{ paddingRight: '6px' }}></FontAwesomeIcon> {this.state.practitionerName}
        <br />
        <FontAwesomeIcon icon={faClinicMedical} style={{ paddingRight: '2px' }}></FontAwesomeIcon>{' '}
        {this.state.organizationName}
      </Typography>
    ) : (
      <div style={{ position: 'relative', left: 110, top: 20 }}>
        <TailSpin ariaLabel="loading-indicator" color="#21264e" height="20" width="20" />
      </div>
    );

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="homeContainer">
          <Card className="statCard">
            <CardContent>
              <Typography className="cardTitle" variant="h7–">
                {t('Logged in as')}
              </Typography>
              {userInfo}
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Stats);
