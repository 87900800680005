/*
Copyright 2024 Awesome Technologies Innovationslabor GmbH

All rights reserved
*/

import React from 'react';
import './Thumbnail.css';
import { TailSpin } from 'react-loader-spinner';
import LazyLoadingImages from '../../utils/LazyLoadingImages';
import { withTranslation, useTranslation } from 'react-i18next';

/**
 * @typedef Props
 * @prop {boolean} isLoading Shows the loading state while true
 * @prop {string} [title] The name underneath the thumbnail
 * @prop {any} content The component that should be displayed
 * @prop {string} [type] What type the file has (gets displayed as an icon in the bottom right)
 * @prop {Function} onClick What happens when the thumbnail is clicked
 */

/**
 * Component for showing thumbnails of content. Use optional chaining (?.) in all props that need to be loaded.
 *
 * @param {Props} props
 *
 * @example
 * <Thumbnail
 *    isLoading={!this.state.resource[index]}
 *    content={this.state.resource[index]?.data}
 *    title={this.state.resource[index]?.title}
 *    onClick={() => {this.doSomething(this.state.resource[index]?.data)}}
 *    type={this.state.resource[index]?.type}
 * />
 */
const Thumbnail = ({ isLoading = false, title = '', content = null, onClick = null, type = null }) => {
  const { t } = useTranslation();

  // This controls the little file icon in the bottom right corner of the thumbnail
  const getTypeIcon = type => {
    if (type == 'pdf') {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512" fill="currentColor">
          <path d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
        </svg>
      );
    } else if (type == 'img') {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512" fill="currentColor">
          <path d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
        </svg>
      );
    } else {
      return null;
    }
  };

  // The skeleton with the loading spinner is shown when the thumbnail is loading
  return isLoading ? (
    <button className="ThumbnailSkeleton">
      <div className="ThumbnailSkeleton__content">
        <TailSpin
          ariaLabel="loading-indicator"
          color="#21264e"
          height="32"
          width="32"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        />
      </div>
      <span className="ThumbnailSkeleton__title">{t('Loading..')}</span>
    </button>
  ) : (
    <button className="Thumbnail" title={title} onClick={onClick}>
      <div className="Thumbnail__content">
        <LazyLoadingImages>{content}</LazyLoadingImages>
        {!!type && <div className="Thumbnail__fileType">{getTypeIcon(type)}</div>}
      </div>
      <span className="Thumbnail__title">{title}</span>
    </button>
  );
};

export default withTranslation()(Thumbnail);
