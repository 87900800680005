import React from 'react';
import '../../css/views/NewCase.scss';
import { withTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';
import { FhirClientContext } from '../../FhirClientContext';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { FormControl, Autocomplete, InputAdornment } from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  TextField,
  MenuItem,
  Button,
  FormHelperText,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import history from '../../history';
import { getOwnOrganization, getOwnOrganizationName, getOwnDisplayname, getOwnPractitionerRole } from '../utils/getOwn';
import { getVectorConfig } from '../utils/getConfig';
import { isExternalReference } from '../utils/checkExternalReference';
import HorizontalNonLinearStepper from './DialogStepper';
import colors from './../../css/theme.module.scss';
const steps = ['Create Patient', 'Optional Information', 'Summary'];

/**
 * Wraps everything into `FhirClientProvider` so that any component
 * can have access to the fhir client through the context.
 */
class NewCaseFormular extends React.Component {
  static contextType = FhirClientContext;

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      organizations: [],
      firstName: '',
      lastName: '',
      birthDate: null,
      weight: '',
      height: '',
      gender: 'unknown',
      coverage: '',
      callbackNumber: '',
      senderPerson: '',
      discipline: '',
      errorMandatory: {
        firstName: '',
        lastName: '',
        birthDate: '',
        weight: '',
        height: '',
      },
      errorOptional: {
        callbackNumber: '',
        coverage: '',
      },
      ownOrganizationId: '',
      ownOrganizationName: '',
      ownPractitionerRole: '',
      showPerformerSelector: false,
      selectedPerformers: [],
      isConference: false,
      caseConferencesEnabled: false,
      hasFederations: false,
      federations: [],
      isSendButtonDisabled: true,
      healthcareServices: [],
      providedHcServices: {},
      open: false,
      showDataEntryDialog: false,
      activeStep: 0,
      completed: {},
    };
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  totalSteps = () => {
    return steps.length;
  };

  completedSteps = () => {
    return Object.keys(this.state.completed).length;
  };

  isLastStep = () => {
    return this.state.activeStep === this.totalSteps() - 1;
  };

  allStepsCompleted = () => {
    return this.completedSteps() === this.totalSteps();
  };

  handleNext = () => {
    const newActiveStep =
      this.isLastStep() && !this.allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in this.state.completed))
        : this.state.activeStep + 1;

    // value check and sanity check
    if (this.state.activeStep === 0) {
      if (!this.checkMandatoryValues()) return;
    }

    if (this.state.activeStep === 1) {
      this.checkOptionalValues();
    }

    const newCompleted = this.state.completed;
    newCompleted[this.state.activeStep] = true;
    this.setState({ activeStep: newActiveStep, completed: newCompleted });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  loadData() {
    const client = this.context.client;

    // we need to download all healthcare services of every federated network as well as our own
    // we then save this into a new object to be sure to have the matching server address as well
    // key value pair with healthcare type as key, value an object of server and healthcareservice

    client
      .request(`/List?code=federation`, {
        pageLimit: 0,
        flat: true,
      })
      .then(data => {
        this.setState({ organizations: [] });
        if (data[0] && data[0].entry) {
          this.setState({ hasFederations: data[0].entry.length > 0 });
          data[0].entry.forEach(federatedInstance => {
            this.setState({
              federations: [
                ...this.state.federations,
                { title: federatedInstance.item.display, url: federatedInstance.item.identifier.value },
              ],
            });
          });
        }
        client
          .request('/HealthcareService', { pageLimit: 0, flat: true, resolveReferences: ['providedBy'] })
          .then(hcsData => {
            if (hcsData) {
              hcsData.filter(data => data.identifier[0].system === 'clinic.amp.specialty');
              this.mapDataToState(hcsData, null);
            }
            if (this.state.hasFederations) {
              this.state.federations
                .map(fed => fed.url)
                .map(fedUrl => {
                  client
                    .request(
                      { url: '/HealthcareService', federatedServerUrl: fedUrl },
                      { pageLimit: 0, flat: true, resolveReferences: ['providedBy'] }
                    )
                    .then(hcData => {
                      if (hcData) {
                        hcData.filter(data => data.identifier[0].system === 'clinic.amp.specialty');
                        this.mapDataToState(hcData, fedUrl);
                      }
                    });
                });
            }
          });
      });
  }

  mapDataToState = (healthcareServiceData, serverUrl) => {
    const hcKeys = Object.keys(this.state.providedHcServices);
    let dataToSave = this.state.providedHcServices;

    // structure of the state variable providedHcServices: { key: healthcareservice identifier string, value: [healthcareservice object] }
    healthcareServiceData.map(data => {
      data.fedUrl = serverUrl;
      if (hcKeys.includes(data.identifier[0].value)) {
        dataToSave[data.identifier[0].value].push(data);
      } else {
        dataToSave[data.identifier[0].value] = [data];
        hcKeys.push(data.identifier[0].value);
      }
    });

    this.setState({ providedHcServices: dataToSave });
  };

  componentWillUnmount() {}

  async componentDidMount() {
    const config = await getVectorConfig('/config');
    this.setState({ caseConferencesEnabled: config.enable_caseconferences, open: Boolean(this.state.anchorEl) });

    // check for own organization
    const client = this.context.client;
    const ownOrg = await getOwnOrganization(client);
    const ownOrgName = await getOwnOrganizationName(client);
    const ownDisplayname = await getOwnDisplayname(client);
    const ownPractitionerRole = await getOwnPractitionerRole(client);
    this.setState({
      ownOrganizationId: ownOrg,
      ownOrganizationName: ownOrgName,
      ownPractitionerRole: ownPractitionerRole,
      senderPerson: ownDisplayname,
    });

    this.loadData();
  }

  onBirthDateChange = newValue => {
    // dont save date into state if it is invalid (occurs when a date is partly entered)
    if (newValue instanceof Date && isNaN(newValue)) return;

    this.setState({ birthDate: newValue, errorMandatory: { birthDate: '' } });
  };

  onFirstNameChange = newValue => {
    this.setState({ firstName: newValue.target.value, errorMandatory: { firstName: '' } });
  };

  onLastNameChange = newValue => {
    this.setState({ lastName: newValue.target.value, errorMandatory: { lastName: '' } });
  };

  onWeightChange = newValue => {
    this.setState({ weight: newValue.target.value, errorMandatory: { weight: '' } });
  };

  onHeightChange = newValue => {
    this.setState({ height: newValue.target.value, errorMandatory: { height: '' } });
  };

  onGenderChange = newValue => {
    this.setState({ gender: newValue.target.value });
  };

  onInsuranceChange = newValue => {
    this.setState({ coverage: newValue.target.value, errorOptional: { coverage: '' } });
  };

  onCallbackNumberChange = newValue => {
    this.setState({ callbackNumber: newValue.target.value, errorOptional: { callbackNumber: '' } });
  };

  onContactPersonChange = newValue => {
    this.setState({ senderPerson: newValue.target.value, errorOptional: { senderPerson: '' } });
  };

  onConsultationSubmit = () => {
    this.setState({ isConference: this.state.selectedPerformers.length > 0 });
    this.createConsultation();
  };

  createConsultation = () => {
    // generate effectiveDateTime with "2020-11-26T11:21:39.8934Z"
    this.setState({ isSendButtonDisabled: true });
    const effectiveDateTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSSXXX");

    // generate ressources
    const client = this.context.client;

    // create patient
    const patient = {
      resourceType: 'Patient',
      active: true,
      name: [
        {
          family: this.state.lastName,
          given: [this.state.firstName],
        },
      ],
      gender: this.state.gender,
      birthDate: this.state.birthDate,
      contact: [
        {
          name: {
            family: this.state.senderPerson,
          },
          telecom: [
            {
              value: this.state.callbackNumber,
            },
          ],
          address: {
            use: 'work',
            text: this.state.senderOrgName,
          },
        },
      ],
      managingOrganization: {
        reference: this.state.senderOrg,
      },
    };

    client.create(patient).then(data => {
      console.log(data);

      const patientId = 'Patient/' + data.id;

      // create observations
      const weight = {
        resourceType: 'Observation',
        status: 'final',
        category: [
          {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'vital-signs',
                display: 'Vital Signs',
              },
            ],
            text: 'vital-signs',
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '29463-7',
              display: 'Body Weight',
            },
          ],
        },
        subject: {
          reference: patientId,
        },
        effectiveDateTime: effectiveDateTime,
        valueQuantity: {
          value: this.state.weight,
          unit: 'kg',
        },
      };

      client.create(weight);

      const height = {
        resourceType: 'Observation',
        status: 'final',
        category: [
          {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'vital-signs',
                display: 'Vital Signs',
              },
            ],
            text: 'vital-signs',
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '8302-2',
              display: 'Body Height',
            },
          ],
        },
        subject: {
          reference: patientId,
        },
        effectiveDateTime: effectiveDateTime,
        valueQuantity: {
          value: this.state.height,
          unit: 'cm',
        },
      };

      client.create(height);

      // create coverage
      const coverage = {
        resourceType: 'Coverage',
        status: 'active',
        policyHolder: {
          reference: patientId,
        },
        beneficiary: {
          reference: patientId,
        },
        payor: [
          {
            reference: patientId,
          },
        ],
        class: [
          {
            type: {
              text: 'Insurance',
            },
            value: 'Insurance',
            name: this.state.coverage,
          },
        ],
      };

      client.create(coverage);

      // create ServiceRequest
      const requester = this.state.ownPractitionerRole ? this.state.ownPractitionerRole : this.state.ownOrganizationId;
      const isFederatedSR = this.state.selectedPerformers.filter(performer => performer.fedUrl != null).length > 0;
      const performerList = this.state.selectedPerformers.map(performer => {
        return { reference: 'Organization/' + performer.providedBy.id };
      });
      const federationPerformerList = this.state.selectedPerformers.map(performer => {
        return { reference: performer.fedUrl + 'Organization/' + performer.providedBy.id };
      });

      const serviceRequest = {
        resourceType: 'ServiceRequest',
        status: 'active',
        intent: 'plan',
        category: [
          {
            text: this.state.discipline,
          },
        ],
        priority: 'asap',
        subject: {
          reference: patientId,
        },
        authoredOn: effectiveDateTime,
        requester: {
          reference: requester,
        },
        performer: isFederatedSR ? federationPerformerList : performerList,
      };

      client.create(serviceRequest).then(data => {
        const accessToken = client.state.tokenResponse.access_token;
        if (isFederatedSR) {
          const clientServerUrl = client.state.serverUrl.endsWith('/')
            ? client.state.serverUrl
            : client.state.serverUrl + '/';

          const shadowServiceRequest = {
            resourceType: 'ServiceRequest',
            status: 'active',
            intent: 'plan',
            basedOn: [
              {
                reference: clientServerUrl + 'ServiceRequest/' + data.id,
              },
            ],
            subject: {
              reference: clientServerUrl + patientId,
            },
            authoredOn: effectiveDateTime,
            requester: {
              reference: clientServerUrl + this.state.ownOrganizationId,
            },
            performer: performerList,
          };

          client
            .create(shadowServiceRequest, {
              url: this.state.selectedPerformers[0].federatedServer,
              federatedServerUrl: this.state.selectedPerformers[0].federatedServer,
              headers: { Authorization: 'Bearer ' + accessToken },
            })
            .then(data => {
              console.log(data);
            });
        }
        this.setState({ isSendButtonDisabled: false });

        // change view
        history.push(`/casedetail/` + data.id);
      });
    });
  };

  checkMandatoryValues = () => {
    const { t } = this.props;
    let errorState = {
      firstName: '',
      lastName: '',
      birthDate: '',
      weight: '',
      height: '',
    };
    let errorOccured = false;

    if (this.state.firstName == '') {
      errorState.firstName = t("Can't be empty");
      errorOccured = true;
    }

    if (this.state.lastName == '') {
      errorState.lastName = t("Can't be empty");
      errorOccured = true;
    }

    if (this.state.birthDate == '' || this.state.birthDate == null) {
      errorState.birthDate = t("Can't be empty");
      errorOccured = true;
    }

    if ((this.state.weight != '' && !isFinite(String(this.state.weight).trim() || NaN)) || +this.state.weight < 0) {
      errorState.weight = t('Has to be a valid number');
      errorOccured = true;
    }

    // Weight validation
    if (!this.state.weight || isNaN(this.state.weight) || this.state.weight <= 0 || this.state.weight > 500) {
      errorState.weight = t('Invalid weight. Please enter a positive number less than 500.');
      errorOccured = true;
    }

    // Height validation
    if (!this.state.height || isNaN(this.state.height) || this.state.height <= 0 || this.state.height > 300) {
      errorState.height = t('Invalid height. Please enter a positive number less than 300.');
      errorOccured = true;
    }

    // Birthdate validation
    const currentDate = new Date();
    const birthDate = new Date(this.state.birthDate);
    const maxAgeDate = new Date(currentDate.getFullYear() - 120, currentDate.getMonth(), currentDate.getDate());

    if (!this.state.birthDate || birthDate > currentDate || birthDate < maxAgeDate || isNaN(birthDate.getTime())) {
      errorState.birthDate = t('Invalid birth date. Please enter a realistic date that is not in the future.');
      errorOccured = true;
    }

    if ((this.state.height != '' && !isFinite(String(this.state.height).trim() || NaN)) || +this.state.height < 0) {
      errorState.height = t('Has to be a valid number');
      errorOccured = true;
    }

    if (!this.state.firstName.match(/[\p{L}\s\-]*/u)) {
      errorState.firstName = t("Input doesn't match sanity rules");
      errorOccured = true;
    }

    if (!this.state.lastName.match(/[\p{L}\s\-]*/u)) {
      errorState.lastName = t("Input doesn't match sanity rules");
      errorOccured = true;
    }

    // check that birthdate is not in the future
    if (this.state.birthDate > new Date()) {
      errorState.birthDate = t('Invalid date');
      errorOccured = true;
    }

    // check that birthdate is a valid date
    if (isNaN(this.state.birthDate)) {
      errorState.birthDate = t('Invalid date');
      errorOccured = true;
    }

    if (this.state.height != '' && !this.state.height.match(/^[\0-9]+$/)) {
      errorState.height = t("Input doesn't match sanity rules");
      errorOccured = true;
    }

    if (this.state.weight != '' && !this.state.weight.match(/^[\0-9]+$/)) {
      errorState.weight = t("Input doesn't match sanity rules");
      errorOccured = true;
    }

    this.setState({ errorMandatory: errorState });

    if (errorOccured) {
      return false;
    }

    return true;
  };

  checkOptionalValues = () => {
    const { t } = this.props;
    let errorState = {
      callbackNumber: '',
      senderPerson: '',
      coverage: '',
    };
    let errorOccured = false;

    if (!this.state.coverage.match(/[\p{L}0-9\s\.\-]*/u)) {
      errorState.coverage = t("Input doesn't match sanity rules");
      errorOccured = true;
    }

    // check that phone numbers, weight, height only have numbers and no letters
    if (this.state.callbackNumber != '' && !this.state.callbackNumber.match(/^[\+0-9]+$/)) {
      errorState.callbackNumber = t("Input doesn't match sanity rules");
      errorOccured = true;
    }

    this.setState({ errorOptional: errorState });

    if (errorOccured) {
      return false;
    }

    return true;
  };

  showDataEntryDialog = () => {
    this.setState({ showDataEntryDialog: true });
  };

  cancelDataEntryDialog = () => {
    this.setState({ showDataEntryDialog: false });
  };

  handleChange = (event, val, service) => {
    this.setState({ selectedPerformers: val, discipline: service });
  };

  render() {
    const { t } = this.props;
    const gender = ['male', 'female', 'other', 'unknown'];

    const mandatoryData = (
      <div>
        <div className="firstNameInput caseData">
          <FormHelperText style={{ fontFamily: 'SourceSansPro' }}>{t('First Name')}</FormHelperText>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={this.state.firstName}
            onChange={this.onFirstNameChange}
            error={this.state.errorMandatory.firstName ? true : false}
            helperText={this.state.errorMandatory.firstName}
            className="textfield newCaseFormularField"
            required
          />
        </div>
        <div className="lastNameInput caseData">
          <FormHelperText style={{ fontFamily: 'SourceSansPro' }}>{t('Last Name')}</FormHelperText>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={this.state.lastName}
            onChange={this.onLastNameChange}
            error={this.state.errorMandatory.lastName ? true : false}
            helperText={this.state.errorMandatory.lastName}
            className="textfield newCaseFormularField"
            required
          />
        </div>
        <div className="birthdateInput caseData">
          <FormHelperText style={{ fontFamily: 'SourceSansPro' }}>{t('Birthdate')}</FormHelperText>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
            <DesktopDatePicker
              mask="__.__.____"
              inputRef={parseISO('dd.MM.yyyy')}
              value={this.state.birthDate}
              onChange={this.onBirthDateChange}
              disableFuture={true}
              required
              slotProps={{
                textField: {
                  variant: 'outlined',
                  helperText: this.state.errorMandatory.birthDate,
                  className: 'newCaseFormularField',
                  error: this.state.errorMandatory.birthDate ? true : false,
                },
              }}
            />
          </LocalizationProvider>
        </div>
        <div className="heightWeightGenderInput">
          <div className="heightInput caseData">
            <FormHelperText style={{ fontFamily: 'SourceSansPro' }}>{t('Height')}</FormHelperText>
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={this.state.height}
              onChange={this.onHeightChange}
              error={this.state.errorMandatory.height ? true : false}
              helperText={this.state.errorMandatory.height}
              className="textfield newCaseFormularField"
              required
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </div>
          <div className="weightInput caseData">
            <FormHelperText style={{ fontFamily: 'SourceSansPro' }}>{t('Weight')}</FormHelperText>
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={this.state.weight}
              onChange={this.onWeightChange}
              error={this.state.errorMandatory.weight ? true : false}
              helperText={this.state.errorMandatory.weight}
              className="textfield newCaseFormularField"
              required
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
              }}
            />
          </div>
          <div className="genderInput caseData">
            <FormControl>
              <FormHelperText style={{ fontFamily: 'SourceSansPro' }}>{t('Gender')}</FormHelperText>
              <Select
                id="gender"
                variant="outlined"
                width="20vw"
                value={this.state.gender}
                onChange={this.onGenderChange}
                required={true}
                style={{ fontFamily: 'SourceSansPro' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                className="newCaseFormularField"
              >
                <MenuItem style={{ fontFamily: 'SourceSansPro' }} value={gender[0]}>
                  {t(gender[0])}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'SourceSansPro' }} value={gender[1]}>
                  {t(gender[1])}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'SourceSansPro' }} value={gender[2]}>
                  {t(gender[2])}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'SourceSansPro' }} value={gender[3]}>
                  {t(gender[3])}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    );

    const optionalData = (
      <div>
        <div className="insuranceInput caseData">
          <FormHelperText style={{ fontFamily: 'SourceSansPro' }}>{t('Insurance')}</FormHelperText>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={this.state.coverage}
            onChange={this.onInsuranceChange}
            error={this.state.errorOptional.coverage ? true : false}
            helperText={this.state.errorOptional.coverage}
            className="textfield newCaseFormularField"
            required
          />
        </div>
        <div className="requestingClinicInput caseData">
          <FormHelperText style={{ fontFamily: 'SourceSansPro' }}>{t('Requesting clinic')}</FormHelperText>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={this.state.ownOrganizationName}
            deactivated="true"
            className="textfield newCaseFormularField"
            required
          />
        </div>
        <div className="contactPersonInput caseData">
          <FormHelperText style={{ fontFamily: 'SourceSansPro' }}>{t('Contact person')}</FormHelperText>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={this.state.senderPerson}
            onChange={this.onContactPersonChange}
            error={this.state.errorOptional.senderPerson ? true : false}
            helperText={this.state.errorOptional.senderPerson}
            className="textfield newCaseFormularField"
            required
          />
        </div>
        <div className="callbackNumberInput caseData">
          <FormHelperText style={{ fontFamily: 'SourceSansPro' }}>{t('Callback Number')}</FormHelperText>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={this.state.callbackNumber}
            onChange={this.onCallbackNumberChange}
            error={this.state.errorOptional.callbackNumber ? true : false}
            helperText={this.state.errorOptional.callbackNumber}
            className="textfield newCaseFormularField"
          />
        </div>
      </div>
    );

    const summary = (
      <div>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            md={6}
            style={{
              borderRight: '2px solid',
              borderImage: 'linear-gradient(#ffffff, ' + colors.ampBackground + ', #ffffff) 10',
              paddingRight: '1em',
            }}
          >
            <Typography
              sx={{ mt: 4, mb: 2 }}
              style={{ fontFamily: 'SourceSansPro', display: 'flex', justifyContent: 'flex-end' }}
              variant="h6"
              component="div"
            >
              {t('Patient data')}
            </Typography>
            <List dense={true}>
              <ListItem>
                <ListItemText
                  primary={this.state.firstName}
                  secondary={t('First Name')}
                  className="amp secondaryText left"
                  style={{ fontFamily: 'SourceSansPro' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={this.state.lastName}
                  secondary={t('Last Name')}
                  className="amp secondaryText left"
                  style={{ fontFamily: 'SourceSansPro' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={this.state.birthDate ? format(new Date(this.state.birthDate), 'dd.MM.yyyy') : ''}
                  secondary={t('Birthdate')}
                  className="amp secondaryText left"
                  style={{ fontFamily: 'SourceSansPro' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={this.state.weight}
                  secondary={t('Weight')}
                  className="amp secondaryText left"
                  style={{ fontFamily: 'SourceSansPro' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={this.state.height}
                  secondary={t('Height')}
                  className="amp secondaryText left"
                  style={{ fontFamily: 'SourceSansPro' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={t(this.state.gender)}
                  secondary={t('Gender')}
                  className="amp secondaryText left"
                  style={{ fontFamily: 'SourceSansPro' }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            style={{
              paddingLeft: '1em',
            }}
          >
            <Typography
              sx={{ mt: 4, mb: 2 }}
              variant="h6"
              component="div"
              style={{ fontFamily: 'SourceSansPro', display: 'flex', justifyContent: 'flex-start' }}
            >
              {t('Optional Information')}
            </Typography>
            <List dense={true}>
              <ListItem>
                <ListItemText
                  primary={this.state.coverage}
                  secondary={t('Insurance')}
                  className="amp secondaryText right"
                  style={{ fontFamily: 'SourceSansPro' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={this.state.callbackNumber}
                  secondary={t('Callback Number')}
                  className="amp secondaryText right"
                  style={{ fontFamily: 'SourceSansPro' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={this.state.senderPerson}
                  secondary={t('Contact person')}
                  className="amp secondaryText right"
                  style={{ fontFamily: 'SourceSansPro' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={this.state.ownOrganizationName}
                  secondary={t('Requesting clinic')}
                  className="amp secondaryText right"
                  style={{ fontFamily: 'SourceSansPro' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={this.state.selectedPerformers.map(performer => performer.name).join(', ')}
                  secondary={t('Selected clinic')}
                  className="amp secondaryText right"
                  style={{ fontFamily: 'SourceSansPro' }}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>
    );

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h2 className="pageTitle">{t('Create consultation')}</h2>
          <div className="healthCareServices">
            {Object.keys(this.state.providedHcServices).length > 0 ? (
              Object.keys(this.state.providedHcServices).map(service => {
                return (
                  <Autocomplete
                    key={service}
                    value={this.state.selectedPerformers.filter(o => o.identifier[0].value == service) || []}
                    sx={{ m: 1, width: 500 }}
                    multiple
                    options={
                      this.state.selectedPerformers[0] === undefined ||
                      this.state.selectedPerformers[0].identifier[0].value !== service
                        ? this.state.providedHcServices[service].map(o => o)
                        : this.state.providedHcServices[service]
                            .filter(service => service.fedUrl === this.state.selectedPerformers[0].fedUrl)
                            .map(o => o)
                    }
                    getOptionLabel={option => option.name}
                    disableCloseOnSelect
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        key={this.state.providedHcServices[service][0]?.id}
                        label={this.state.providedHcServices[service][0]?.type[0].coding[0].display}
                      />
                    )}
                    onChange={(event, val) => this.handleChange(event, val, service)}
                  />
                );
              })
            ) : (
              <div />
            )}
          </div>

          <Dialog
            open={this.state.showDataEntryDialog}
            onClose={this.cancelDataEntryDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t('Create Patient')}</DialogTitle>
            <DialogContent height="200px">
              {this.state.activeStep === 0 ? mandatoryData : this.state.activeStep === 1 ? optionalData : summary}
            </DialogContent>
            <DialogActions>
              <HorizontalNonLinearStepper
                totalSteps={this.totalSteps}
                completedSteps={this.completedSteps}
                isLastStep={this.isLastStep}
                allStepsCompleted={this.allStepsCompleted}
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                onConsultationSubmit={this.onConsultationSubmit}
                activeStep={this.state.activeStep}
                completed={this.state.completed}
                steps={steps}
                errorMandatory={this.state.errorMandatory}
                errorOptional={this.state.errorOptional}
                sendButtonDisabled={this.state.isSendButtonDisabled}
              />
            </DialogActions>
          </Dialog>
        </div>
        <Button
          onClick={this.showDataEntryDialog}
          disabled={Object.values(this.state.selectedPerformers).every(o => o.length === 0)}
          variant="contained"
          style={{ marginTop: '1rem' }}
        >
          {t('Create consultation now')}
        </Button>
      </div>
    );
  }
}

export default withTranslation()(NewCaseFormular);
