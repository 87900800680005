import React from 'react';
import clsx from 'clsx';
import '../../css/views/CaseList.scss';
import colors from '../../css/theme.module.scss';
import { FhirClientContext } from '../../FhirClientContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faUsers } from '@fortawesome/free-solid-svg-icons';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { DataGrid, deDE, enUS } from '@material-ui/data-grid';
import { format, parseISO, formatDistanceToNowStrict } from 'date-fns';
import { de } from 'date-fns/locale';
import { withTranslation } from 'react-i18next';
import history from '../../history';
import { getOwnOrganization, getOwnPractitionerRole } from '../utils/getOwn';
import { getExternalServerUrl, getExternalServiceRequestId } from '../utils/checkExternalReference';
import { t } from 'i18next';

class Cases extends React.Component {
  static contextType = FhirClientContext;
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      caseList: [],
      dataFetched: false,
      pageSize: 25,
      ownOrganization: '',
      language: localStorage.getItem('i18nextLng') ?? 'en',
      menuLang: enUS,
    };

    this.serviceRequestStatus = {
      Draft: 0,
      MessageSeen: 1,
      ReportConfirmed: 2,
      MessageNotSeen: 3,
      ReportUnconfirmed: 4,
      onHold: 5,
      CloseCase: 6,
    };
    Object.freeze(this.serviceRequestStatus);
    this.statusTranslation = [
      'Draft',
      'MessageSeen',
      'ReportConfirmed',
      'MessageNotSeen',
      'ReportUnconfirmed',
      'onHold',
      'CloseCase',
    ];
  }

  async loadData() {
    const client = this.context.client;

    let request = this.props.showArchive ? `ServiceRequest?status=completed` : `ServiceRequest?status=active,on-hold`;

    client
      .request(request, {
        pageLimit: 0,
        flat: true,
        resolveReferences: ['requester', 'performer', 'subject'],
      })
      .then(async cases => {
        if (cases.length == 0) this.setState({ dataFetched: true });

        let validServiceRequests = cases
          .filter(o => o.basedOn === undefined)
          .map(i => (i = { sr: i, federatedServerUrl: null, shadowSrId: null }));
        let externalSrs = cases.filter(o => o.basedOn !== undefined && getExternalServerUrl(o, client));
        let counter = externalSrs.length;

        if (externalSrs.length !== 0) {
          externalSrs.forEach(async exSr => {
            await client
              .request(
                {
                  url: 'ServiceRequest/' + getExternalServiceRequestId(exSr, client),
                  federatedServerUrl: getExternalServerUrl(exSr, client),
                },
                {
                  pageLimit: 0,
                  flat: true,
                  resolveReferences: ['requester', 'performer'],
                }
              )
              .then(srData => {
                validServiceRequests.push({
                  sr: srData,
                  federatedServerUrl: getExternalServerUrl(exSr, client),
                  shadowSrId: exSr.id,
                });
                counter = counter - 1;

                if (counter === 0) {
                  this.createNewEntriesFromServiceRequests(validServiceRequests);
                }
              });
          });
        } else {
          this.createNewEntriesFromServiceRequests(validServiceRequests);
        }
      });
  }

  createNewEntriesFromServiceRequests = cases => {
    const client = this.context.client;
    let caseMap = [];

    cases.forEach(o => {
      let emptyCases = [];
      // format patient name
      let entry = null;
      let patientName = '';
      let name = '';
      if (o.federatedServerUrl !== null) {
        client
          .request(
            { url: o.sr.subject.reference, federatedServerUrl: o.federatedServerUrl },
            { pageLimit: 0, flat: true }
          )
          .then(patientData => {
            patientName = this.buildPatientName(patientData.name);
            o.sr.subject = patientData;
          });
      } else {
        patientName = this.buildPatientName(o.sr.subject.name);
      }

      // search all communications basedOn this SR to extract status
      client
        .request(
          {
            url: `Communication?based-on=ServiceRequest/${
              getExternalServiceRequestId(o.sr, client) ?? o.sr.id
            }&_elements=status,partOf`,
            federatedServerUrl: o.federatedServerUrl,
          },
          {
            pageLimit: 0,
            flat: true,
          }
        )
        .then(async coms => {
          let station = [];
          client
            .request('/HealthcareService?organization=Organization/' + o.sr.performer[0].id, {
              flat: true,
              pageLimit: 0,
            })
            .then(async healthcareServices => {
              station = healthcareServices
                .filter(service => service.identifier[0].value == o.sr.category[0].text)
                .map(cat => {
                  return cat.type[0].coding[0].display;
                });

              // calculate status of ServiceRequest
              let status = this.serviceRequestStatus.Draft;
              let srStatus = o.sr.status;

              if (srStatus == 'on-hold' || srStatus == 'completed') {
                // check if case close request was sent
                if (srStatus == 'on-hold') {
                  if (status < this.serviceRequestStatus.onHold) {
                    status = this.serviceRequestStatus.onHold;
                  }
                }

                // check if case close request was accepted
                // TODO check && o.supportingInfo != null nötig?
                if (srStatus == 'completed') {
                  status = this.serviceRequestStatus.CloseCase;
                }
              } else {
                coms.forEach(o => {
                  // check if case has unconfirmed diagnostic report
                  if (o.status == 'not-done' && o.partOf != null) {
                    if (status < this.serviceRequestStatus.ReportUnconfirmed) {
                      status = this.serviceRequestStatus.ReportUnconfirmed;
                    }
                  }

                  // check if unseen messages are present
                  if (o.status == 'in-progress') {
                    if (status < this.serviceRequestStatus.MessageNotSeen) {
                      status = this.serviceRequestStatus.MessageNotSeen;
                    }
                  }

                  // check if a diagnostic report is present and was confirmed
                  if (o.status == 'completed' && o.partOf != null) {
                    if (status < this.serviceRequestStatus.ReportConfirmed) {
                      status = this.serviceRequestStatus.ReportConfirmed;
                    }
                  }

                  // check if seen messages are present
                  if (o.status == 'completed' && o.partOf == null) {
                    if (status < this.serviceRequestStatus.MessageSeen) {
                      status = this.serviceRequestStatus.MessageSeen;
                    }
                  }
                });
              }

              // requester or performer?
              let clinic;
              const caseRequester = o.sr.requester.resourceType + '/' + o.sr.requester.id;
              const ownPractitionerRole = await getOwnPractitionerRole(client);

              if (this.state.ownOrganization == 'Organization/' + o.sr.performer[0].id) {
                if (o.sr.requester.resourceType != 'Organization') {
                  if (o.sr.requester.organization.reference) {
                    clinic = await client
                      .request(`${o.sr.requester.organization.reference}`, {
                        pageLimit: 0,
                        flat: true,
                      })
                      .then(org => {
                        clinic = {
                          incoming: true,
                          name: [client.getPath(org, 'name')],
                          isCaseConference: o.sr.performer.length > 1,
                        };
                        return clinic;
                      });
                  } else {
                    clinic = {
                      incoming: true,
                      name: [''],
                      isCaseConference: o.sr.performer.length > 1,
                    };
                  }
                } else {
                  clinic = {
                    incoming: true,
                    name: [o.sr.requester.name],
                    isCaseConference: o.sr.performer.length > 1,
                  };
                }
              } else {
                clinic = {
                  incoming: false,
                  name: o.sr.performer.map(perform => perform.name),
                  isCaseConference: o.sr.performer.length > 1,
                };
              }

              // add entry to list of cases
              let newEntry = {
                id: o.sr.id,
                shadowSrId: o.shadowSrId,
                status: t(this.statusTranslation[status]),
                name: patientName,
                gender: t(o.sr.subject.gender),
                age: formatDistanceToNowStrict(parseISO(o.sr.subject.birthDate), {
                  unit: 'year',
                  locale: de,
                }),
                station: station,
                clinic: clinic,
                lastContact: format(parseISO(o.sr.authoredOn), 'P', { locale: de }),
                lastUpdated: parseISO(o.sr.authoredOn),
              };

              if (status === 0 && coms.length === 0 && ownPractitionerRole != caseRequester) {
                emptyCases = [...emptyCases, o.sr.id];
              }

              caseMap = [...caseMap, newEntry];

              if (this._isMounted && caseMap.length == cases.length) {
                const caseList = [].concat(caseMap);
                caseList.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
                let filteredCaseList = caseList.filter(o => !emptyCases.includes(o.id));
                this.setState({ caseList: filteredCaseList, dataFetched: true });
              }
            });
        });
    });
  };

  buildPatientName(name) {
    if (!name) return t('No Name');

    const entry = name.find(nameRecord => nameRecord.use === 'official') || name[0];
    if (!entry) return t('No Name');

    return entry.given.join(' ') + ' ' + entry.family;
  }

  componentWillUnmount() {
    this.caseList && this.caseList.destroy();
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;

    // check for own organization
    const client = this.context.client;
    this.setState({
      ownOrganization: await getOwnOrganization(client),
      language: localStorage.getItem('i18nextLng') ?? 'en',
    });

    await this.loadData();

    document.addEventListener('langChange', async e => {
      this.setState({
        caseList: [],
        dataFetched: false,
        language: e.detail,
        menuLang: e.detail === 'de' ? deDE : enUS,
      });
      await this.loadData();
    });
  }

  handleRowClick(e) {
    history.push(`/casedetail/${e.row.shadowSrId ?? e.id}`);
  }

  renderIcon(param) {
    if (param.incoming) {
      return (
        <div>
          <FontAwesomeIcon icon={faArrowLeft} />
          <FontAwesomeIcon
            style={{
              display: param.isCaseConference ? 'inline' : 'none',
              color: colors.ampDarkestGrey,
              marginLeft: '.5em',
            }}
            icon={faUsers}
            title={t('Case conference')}
          />{' '}
          <span title={typeof param.name == 'string' ? param.name : param.name.join(', ')}>
            {typeof param.name == 'string'
              ? param.name
              : param.name.length > 1
                ? param.name[0] + ', ...'
                : param.name[0]}
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <FontAwesomeIcon icon={faArrowRight} />
          <FontAwesomeIcon
            style={{
              display: param.isCaseConference ? 'inline' : 'none',
              color: colors.ampDarkestGrey,
              marginLeft: '.5em',
            }}
            icon={faUsers}
            title={t('Case conference')}
          />{' '}
          <span title={typeof param.name == 'string' ? param.name : param.name.join(', ')}>
            {typeof param.name == 'string'
              ? param.name
              : param.name.length > 1
                ? param.name[0] + ', ...'
                : param.name[0]}
          </span>
        </div>
      );
    }
  }

  render() {
    const { t, i18n } = this.props;
    const theme = createTheme({
      palette: {
        primary: { main: '#1976d2' },
        text: { primary: colors.ampDarkBlue },
      },
      typography: {
        fontFamily: 'SourceSansProSemiBold',
      },
    });
    const columns = [
      {
        field: 'status',
        headerName: t('Status'),
        width: 200,
        cellClassName: params =>
          clsx('amp statusValue', {
            orangeOpacity: params.value == t('Draft'),
            greenOpacity: params.value == t('MessageSeen') || params.value == t('ReportConfirmed'),
            yellowOpacity: params.value == t('MessageNotSeen'),
            redOpacity: params.value == t('ReportUnconfirmed'),
            blueOpacity: params.value == t('onHold'),
            black: params.value == t('CloseCase'),
          }),
        renderCell: params => (
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: 10,
                left: 0,
                marginRight: '0.5em',
                display: params.value == t('CloseCase') ? 'none' : 'inherit',
              }}
              className={clsx('amp statusValue', {
                orange: params.value == t('Draft'),
                green: params.value == t('MessageSeen') || params.value == t('ReportConfirmed'),
                yellow: params.value == t('MessageNotSeen'),
                red: params.value == t('ReportUnconfirmed'),
                blue: params.value == t('onHold'),
                black: params.value == t('CloseCase'),
              })}
            />
            <div className="statusMessage">{params.value}</div>
          </div>
        ),
      },
      { field: 'name', headerName: t('Name'), flex: 1, minWidth: 150 },
      { field: 'gender', headerName: t('Gender'), width: 180 },
      { field: 'age', headerName: t('Age'), width: 120 },
      {
        field: 'station',
        headerName: t('Station'),
        flex: 1,
        minWidth: 150,
        renderCell: params => <span title={params.value}>{params.value[0]}</span>,
      },
      {
        field: 'clinic',
        headerName: t('Clinic'),
        flex: 1,
        minWidth: 150,
        renderCell: params => this.renderIcon(params.value),
        sortComparator: (v1, v2) => v1.name[0].localeCompare(v2.name[0]),
      },
      { field: 'lastContact', headerName: t('last contact'), width: 180 },
    ];

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="contentBody">
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <ThemeProvider theme={theme}>
                <DataGrid
                  rows={this.state.caseList}
                  columns={columns}
                  pageSize={this.state.pageSize}
                  rowsPerPageOptions={[25, 50, 100]}
                  onPageSizeChange={newPageSize => this.setState({ pageSize: newPageSize })}
                  pagination
                  localeText={this.state.menuLang.props.MuiDataGrid.localeText}
                  onRowClick={this.handleRowClick}
                  disableSelectionOnClick={true}
                  loading={!this.state.dataFetched}
                />
              </ThemeProvider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Cases);
