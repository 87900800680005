import React from "react";
import { createRoot } from 'react-dom/client';
import './css/app.scss';
import App from './components/App';
import './i18n';

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);