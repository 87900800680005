/*
Copyright 2024 Awesome Technologies Innovationslabor GmbH

All rights reserved
*/

import React from 'react';
import './PdfThumbnail.css';
import { Document, Page } from 'react-pdf';

/**
 * @typedef Props
 * @prop {string} src The source url of the PDF
 * @prop {React.ReactNode} [style] Styles
 */

/**
 * Component that shows the first page of a PDF
 *
 * @param {Props} props
 */
const PdfThumbnail = ({ src, style, height = 100 }) => {
  return (
    <div className="PdfThumbnail" style={style}>
      <Document file={src} className="PdfThumbnail__document">
        <Page
          pageNumber={1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          height={height}
          className="PdfThumbnail__page"
        />
      </Document>
    </div>
  );
};

export default PdfThumbnail;
